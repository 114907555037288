import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DateTimePicker = ({ label, selected, onChange, time, onTimeChange, minDate, dateFormat, disabled, startTime, startDate }) => {
  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hour = i.toString().padStart(2, '0');
        const minute = j.toString().padStart(2, '0');
        options.push(`${hour}:${minute}`);
      }
    }
    return options;
  };

  const filterTimeOptions = (options) => {
    if (!label.includes('End') || !selected || !startTime) {
      return options;
    }

    if (selected.toDateString() === startDate.toDateString()) {
      return options.filter((option) => option > startTime);
    }

    return options;
  };

  return (
    <div className='d-flex flex-column align-items-start ml-2'>
      <label htmlFor='filter-input' className='mb-0'>
        {label}
      </label>
      <DatePicker
        selected={selected}
        onChange={onChange}
        selectsStart={label.includes('Start')}
        selectsEnd={label.includes('End')}
        startDate={selected}
        endDate={selected}
        minDate={minDate}
        placeholderText={label}
        className='mb-2'
        dateFormat={dateFormat}
        maxDate={new Date()}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        disabled={disabled}
      />
      {selected && (
        <select style={{ width: '100%', height: '30px' }} value={time} onChange={onTimeChange}>
          {filterTimeOptions(generateTimeOptions()).map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};
